<template>
  <section v-if="is_global_admin" class="analytics-module scroll-content">
    <h3 class="md-display-1">{{ $translate("analytics.title") }}:</h3>
    <p class="md-subheading">{{ $translate("analytics.message") }}</p>

    <hr/>

    <p class="md-subheading">{{ $translate("analytics.titles.dates") }}:</p>
    <div class="md-layout">
      <div class="md-layout-item md-size-20 md-small-size-33">
        <md-datepicker v-model="start_time" :md-disabled-dates="verify_start_date" md-immediately/>
      </div>
      <div class="md-layout-item md-size-20 md-small-size-33">
        <md-datepicker v-model="end_time" :md-disabled-dates="verify_end_date" md-immediately/>
      </div>
    </div>

    <hr/>

    <section class="admin-charts">
      <div class="admin-chart">
        <p class="md-title">{{ $translate("analytics.titles.new_vs_returning") }}</p>

        <canvas ref="new_vs_returning_visitors"/>
        <p class="md-subheading" v-if="!ga_reports.new_and_returning || (ga_reports.new_and_returning && !ga_reports.new_and_returning.length)">
          {{ $translate("analytics.titles.no_data") }}
        </p>
        <div v-if="!ga_reports.new_and_returning" class="admin-loader admin-loader--absolute admin-loader--small">
          <Loader :size="loader_sizes.small" />
        </div>
      </div>

      <div class="admin-chart">
        <p class="md-title" v-html="$translate('analytics.titles.geo_view')" />

        <div v-if="!visitor_countries" class="admin-loader admin-loader--absolute admin-loader--small">
          <Loader :size="loader_sizes.small" />
        </div>
        <l-map
          v-if="visitor_countries"
          :zoom="map_options.zoom"
          :center="map_options.center"
          style="height: 300px; width: 100%"
        >
          <l-tile-layer
            :url="map_options.url"
            :attribution="map_options.attribution"
          />
          <l-circle
            v-for="({ latlon, value, country }, index) in visitor_countries"
            :key="index"
            :lat-lng="latlon"
            :radius="150000"
          >
            <l-popup :content="`${country}: ${value}`" />
          </l-circle>
        </l-map>

        <hr/>
        <div>
          <p class="md-subheading">{{ $translate("analytics.labels.top_countries") }}:</p>
          <div v-for="({ country, value, country_code }) in top_countries" :key="country" class="analytics-module__country">
            <img :src="`https://flagcdn.com/h20/${country_code}.webp`">
            <span>{{ country }}: <b>{{ value }}</b></span>
          </div>
        </div>
        <p class="md-subheading" v-if="visitor_countries && visitor_countries.length === 0">{{ $translate("analytics.titles.no_data") }}</p>
      </div>

      <div class="admin-chart">
        <p class="md-title" v-html="$translate('analytics.titles.sources')" />

        <canvas ref="source_of_visitors"/>
        <hr v-if="sorted_sources.length"/>

        <p v-if="visitor_sources && sorted_sources.length" class="md-subheading">{{ $translate("analytics.labels.top_sources") }}:</p>
        <md-table v-if="visitor_sources && sorted_sources.length">
          <md-table-row>
            <md-table-head>{{ $translate("analytics.labels.source") }}</md-table-head>
            <md-table-head>{{ $translate("analytics.labels.new") }}</md-table-head>
            <md-table-head>{{ $translate("analytics.labels.returning") }}</md-table-head>
            <md-table-head>{{ $translate("total") }}</md-table-head>
          </md-table-row>

          <md-table-row v-for="({ new_visitors, returning, name, total }) in sorted_sources" :key="name">
            <md-table-cell class="analytics-module__left-align-column">{{ name }}</md-table-cell>
            <md-table-cell>{{ new_visitors }}</md-table-cell>
            <md-table-cell>{{ returning }}</md-table-cell>
            <md-table-cell>{{ total }}</md-table-cell>
          </md-table-row>
        </md-table>
        <p class="md-subheading" v-if="!visitor_sources || !sorted_sources.length">{{ $translate("analytics.titles.no_data") }}</p>
        <div v-if="!visitor_sources" class="admin-loader admin-loader--absolute admin-loader--small">
          <Loader :size="loader_sizes.small" />
        </div>
      </div>
    </section>
  </section>
  <div v-else class="social-media__admin-message">
    <h3 class="md-headline">
      {{ $translate("global_admin_specific_module") }}
      <a class="md-headline" :href="`https://${admin_domain}/admin/${project_config.project_id}/social/marketing_analytics`">Admin</a>
    </h3>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { LMap, LCircle, LPopup, LTileLayer } from "vue2-leaflet"
import Loader from "@/applications/Shared/components/loader/index.vue"
import { loader_sizes } from "../../../../Shared/components/loader"
import "leaflet/dist/leaflet.css";
import { ADMIN, CONFIGURATION_STORE, SOCIAL_STORE, USER_STORE } from "../../../constants/others_constants"
import {
  FETCH_FACEBOOK_AD_INSIGHTS, FETCH_FACEBOOK_AD_ACCOUNTS, FETCH_FACEBOOK_PIXELS_STATS, FETCH_FACEBOOK_PIXELS,
  FETCH_GA_REPORT
} from "../../../stores/Admin/social/constants"
import { UPDATE_LOADER, FETCH_AND_ASSING_MOMENT_LIBRARY } from "../../../stores/Admin/constants"
import init_chart from "../../../methods/init_chart"
import { classic_date_format, SHARED_STORE } from "../../../../Shared/constants/other"
import { fetch_country_latlon } from "../../../constants/endpoints/firebase"
import { GA_visitor_types } from "../../../constants/social_media_constants"
import { admin_domain } from "../../../../../data/other_constants"

export default {
  components: {
    LMap, LCircle, LPopup, LTileLayer,
    Loader
  },
  data() {
    return {
      map_options: {
        zoom: 1,
        center: [38.52, 34.34], // center of planet
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      },
      visitor_countries: null,
      visitor_sources: null,
      start_time: null,
      end_time: null,
      admin_domain,
      loader_sizes
    }
  },
  computed: {
    ...mapState(SOCIAL_STORE, [
      "connected_fb_pages",
      "connected_fb_groups",
      "connected_ig_accounts",
      "fb_pages_posts",
      "fb_groups_posts",
      "ig_accounts_posts",
      "ga_reports"
    ]),
    ...mapState(CONFIGURATION_STORE, ["project_config"]),
    ...mapState(ADMIN, ["$moment"]),
    ...mapState(USER_STORE, ["permissions"]),
    ...mapState(SHARED_STORE, ["is_global_admin"]),
    top_countries() {
      // Take maximum top 5 countries
      return (this.visitor_countries || []).slice(0, 5)
    },
    sorted_sources() {
      // Take maximum top 5 countries
      return Object
        .entries(this.visitor_sources || {})
        .map(([source, { new_visitors, returning }]) => ({
          name: source, 
          new_visitors, 
          returning, 
          total: new_visitors + returning
        }))
        .sort(({ total }, { total: totalB }) => totalB - total)
    }
  },
  watch: {
    "ga_reports.new_and_returning": {
      handler() {
        this.init_new_vs_returning_visitors()
      },
      deep: true
    },
    "ga_reports.country": {
      handler() {
        this.load_visitor_countries()
      },
      deep: true
    },
    "ga_reports.source": {
      handler() {
        this.init_source_of_visitors()
      },
      deep: true
    },
    start_time(date, old_date) {
      if (
        date &&
        this.enable_date_change_update &&
        date !== old_date
      ) this.fetch_ga_report(this.$moment(date).format("YYYY-MM-DD"))
      if (!date) this.$nextTick(() => this.start_time = old_date)
    },
    end_time(date, old_date) {
      if (
        date &&
        this.enable_date_change_update &&
        date !== old_date
      ) this.fetch_ga_report(this.$moment(date).format("YYYY-MM-DD"))
      if (!date) this.$nextTick(() => this.start_time = old_date)
    }
  },
  async mounted() {
    if (this.is_global_admin) {
      await this.fetch_and_assing_moment()

      const last_thirty_days = this.$moment().subtract(30, "days").format("YYYY-MM-DD")
      this.start_time = last_thirty_days
      this.end_time = this.$moment().format("YYYY-MM-DD")
      this.enable_date_change_update = true

      if (!window.Chart) {
        await this.$loadScript("https://cdn.jsdelivr.net/npm/chart.js")
        await new Promise((resolve) => {
          const interval = setInterval(() => {
            if (window.Chart) {
              clearInterval(interval)
              this.chart_loaded = true
              resolve()
            }
          }, 300);
        })
      } else this.chart_loaded = true

      // await this.fetch_fb_ad_accounts() Finish once decided how to integrate FB analytics
      // await this.fetch_fb_pixels() Finish once decided how to integrate FB analytics
      await Promise.all([
        this.fetch_ga_report(last_thirty_days),
        // this.fetch_fb_pixel_stats(last_thirty_days), Finish once decided how to integrate FB analytics
        // this.fetch_fb_ad_insights() Finish once decided how to integrate FB analytics
      ])

      this.load_visitor_countries()
    }
  },
  methods: {
    ...mapActions(SOCIAL_STORE, {
      fetch_fb_ad_insights: FETCH_FACEBOOK_AD_INSIGHTS,
      fetch_fb_ad_accounts: FETCH_FACEBOOK_AD_ACCOUNTS,
      fetch_fb_pixels: FETCH_FACEBOOK_PIXELS,
      fetch_fb_pixel_stats: FETCH_FACEBOOK_PIXELS_STATS,
      fetch_ga_report: FETCH_GA_REPORT
    }),
    ...mapMutations(ADMIN, {
      update_loader: UPDATE_LOADER,
    }),
    ...mapActions(ADMIN, {
      fetch_and_assing_moment: FETCH_AND_ASSING_MOMENT_LIBRARY
    }),
    init_chart,
    init_new_vs_returning_visitors() {
      if (this.ga_reports.new_and_returning && this.ga_reports.new_and_returning) {
        const base_labels = Array.from(new Set(this.ga_reports.new_and_returning.map(({ dimensions }) => dimensions[0])))
        const formatted_labels = base_labels.map(label => this.$moment(label).format(classic_date_format))
        const transformed_data = base_labels.reduce((tot, timestamp) => {
          const correct_data_objects = {
            returning: this.ga_reports.new_and_returning.find(({ dimensions }) => dimensions[1] === GA_visitor_types.returning && dimensions[0] === timestamp),
            new: this.ga_reports.new_and_returning.find(({ dimensions }) => dimensions[1] === GA_visitor_types.new_visitors && dimensions[0] === timestamp),
          }

          return {
            returning: {
              ...tot.returning,
              [timestamp]: correct_data_objects.returning ? Number(correct_data_objects.returning.metrics[0].values[0]) : 0
            },
            new: {
              ...tot.new,
              [timestamp]: correct_data_objects.new ? Number(correct_data_objects.new.metrics[0].values[0]) : 0
            }
          }
        }, { returning: {}, new: {} })
        const totals = {
          returning: Object.values(transformed_data.returning).reduce((tot, num) => tot + num, 0),
          new: Object.values(transformed_data.new).reduce((tot, num) => tot + num, 0)
        }

        this.init_chart(
          this.$refs.new_vs_returning_visitors,
          formatted_labels,
          [Object.values(transformed_data.returning), Object.values(transformed_data.new)],
          "new_vs_returning_visitors",
          [this.$translate("analytics.labels.returning_visitor") + ` (${totals.returning})`, this.$translate("analytics.labels.new_visitor") + ` (${totals.new})`],
          "line",
          ["#1f881f", "#448aff"],
          ["#1f881f", "#448aff"],
          true
        )
      }
    },
    init_source_of_visitors() {
      if (this.ga_reports.source && this.ga_reports.source) {
        const transformed_data = this.ga_reports.source.reduce((tot, { dimensions, metrics }) => {
          const correct_key = dimensions[0] === "(direct)" ? this.$translate("analytics.labels.direct") : dimensions[0]
          const value = Number(metrics[0].values[0])

          return {
            ...tot,
            [correct_key]: {
              returning: ((tot[correct_key] || {}).returning || 0) + (dimensions[1] === GA_visitor_types.returning ? value : 0),
              new_visitors: ((tot[correct_key] || {}).new_visitors || 0) + (dimensions[1] === GA_visitor_types.new_visitors ? value : 0),
            }
          }
        }, {})

        this.init_chart(
          this.$refs.source_of_visitors,
          Object.keys(transformed_data),
          Object.values(transformed_data).map(({ returning, new_visitors }) => returning + new_visitors),
          "source_of_visitors",
          undefined,
          "doughnut",
          "transparent",
          [
            "#47E023",
            "#FB32C6",
            "#F0BD25",
            "#A0BC65",
            "#535BB1",
            "#964AB9",
            "#D81B11",
            "#0FB9D7",
            "#0F20D6",
            "#C06812",
          ]
        )

        this.visitor_sources = transformed_data
      }
    },
    async load_visitor_countries() {
      if (!this.ga_reports.country) return

      const countries_total = this.ga_reports.country.reduce((tot, { dimensions, metrics }) => ({
        ...tot,
        [dimensions[0]]: metrics[0].values[0]
      }), {})
      const { data } = await fetch_country_latlon(Object.keys(countries_total))

      Object.entries(data).forEach(([country, { latlon, country_code }]) =>
        latlon ?
          countries_total[country] = { latlon, value: countries_total[country], country, country_code } :
          delete countries_total[country]
      )

      this.visitor_countries = Object.values(countries_total).sort(({ value }, { value: valueB }) => valueB - value)
    },
    verify_start_date(date) {
      return this.$moment(this.$moment(date)).isAfter(this.$moment(this.end_time).format());
    },
    verify_end_date(date) {
      return this.$moment(this.$moment(date)).isAfter(this.$moment().format());
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin_charts.scss";

  .analytics-module {
    &__ga-button {
      display: none;
    }

    &__left-align-column {
      text-align: left;
    }

    .md-list-item-content {
      b {
        padding-right: $half-default-size;
      }
    }

    canvas {
      max-height: 400px;
    }

    .md-datepicker {
      min-height: 32px;
      margin: 0 0 10px;
      padding-top: 0;

      .md-input-action {
        display: none;
      }
    }

    &__country {
      display: inline-flex;
      border: 1px solid $blue;
      border-radius: $border-radius;
      align-items: center;
      padding: 5px $half-default-size;
      margin: 0 $half-default-size 5px 0;

      &:last-child {
        margin-right: 0;
      }

      img {
        max-width: 24px;
        height: 24px;
        object-fit: contain;
        margin-right: $half-default-size;
      }
    }

    .md-table-content {
      max-height: 200px;
    }
  }
</style>
